// export default class CircleHover {
//       constructor() {

//         const circles = [
//             document.getElementById("circle-one"),
//             document.getElementById("circle-two"),
//             document.getElementById("circle-three"),
//             document.getElementById("circle-four"),
//           ];
        
//           const smallCircles = [
//             document.getElementById("small-circle-one"),
//             document.getElementById("small-circle-two"),
//             document.getElementById("small-circle-three"),
//             document.getElementById("small-circle-four"),
//           ];
        
//           const descriptions = [
//             document.getElementById("circle-one-description"),
//             document.getElementById("circle-two-description"),
//             document.getElementById("circle-three-description"),
//             document.getElementById("circle-four-description"),
//           ];

//           if (circles !== null) return;
//           if (smallCircles !== null) return;
//           if (descriptions !== null) return;
        
//           const handleCircleMouseEnter = (index) => {
//             resetStyles();
//             circles[index].style.transform = "scale(1.5)";
//             descriptions[index].style.display = "block";
        
//             // Custom rules for each circle
//             switch (index) {
//               case 0:
//                 circles[1].style.transform = "translate(0px, 25px)";
//                 circles[2].style.transform = "translate(5px, 25px)";
//                 circles[3].style.transform = "translate(0px, 0px)";
//                 smallCircles[0].style.transform = "translate(-35px, 55px)";
//                 smallCircles[1].style.transform = "translate(30px, 0px)";
//                 smallCircles[2].style.transform = "translate(-15px, 55px)";
//                 smallCircles[3].style.transform = "translate(-43px, -5px)";
//                 break;
//               case 1:
//                 circles[0].style.transform = "translate(40px, 10px)";
//                 circles[2].style.transform = "translate(10px, 10px)";
//                 circles[3].style.transform = "translate(25px, 5px)";
//                 smallCircles[0].style.transform = "translate(32px, 0px)";
//                 smallCircles[1].style.transform = "translate(65px, 5px)";
//                 smallCircles[2].style.transform = "translate(5px, 40px)";
//                 smallCircles[3].style.transform = "translate(-15px, -20px)";
//                 break;
//               case 2:
//                 circles[0].style.transform = "translate(-12px, -10px)";
//                 circles[1].style.transform = "translate(0px, -10px)";
//                 circles[3].style.transform = "translate(-35px, -4px)";
//                 smallCircles[0].style.transform = "translate(-10px, 23px)";
//                 smallCircles[1].style.transform = "translate(6px, -40px)";
//                 smallCircles[2].style.transform = "translate(-45px, -10px)";
//                 smallCircles[3].style.transform = "translate(-75px, 5px)";
//                 break;
//               case 3:
//                 circles[0].style.transform = "translate(0px, 0px)";
//                 circles[1].style.transform = "translate(-20px, -5px)";
//                 circles[2].style.transform = "translate(20px, -10px)";
//                 smallCircles[0].style.transform = "translate(-18px, 15px)";
//                 smallCircles[1].style.transform = "translate(9px, -36px)";
//                 smallCircles[2].style.transform = "translate(-40px, 20px)";
//                 smallCircles[3].style.transform = "translate(0px, -35px)";
//                 break;
//               default:
//                 break;
//             }
//           };
        
//           circles[0].addEventListener("mouseenter", function() {
//             handleCircleMouseEnter(0);
//           });
//           circles[1].addEventListener("mouseenter", function() {
//             handleCircleMouseEnter(1);
//           });
//           circles[2].addEventListener("mouseenter", function() {
//             handleCircleMouseEnter(2);
//           });
//           circles[3].addEventListener("mouseenter", function() {
//             handleCircleMouseEnter(3);
//           });
        
//           const resetStyles = () => {
//             circles.forEach(
//               (circle) => (circle.style.transform = "translate(0px, 0px)")
//             );
//             smallCircles.forEach(
//               (circle) => (circle.style.transform = "translate(0px, 0px)")
//             );
//             descriptions.forEach((desc) => (desc.style.display = "none"));
//           };
//     }
// }

//module.exports = CircleHover; // If you want to export the class itself

export default class CircleHover {
  constructor() {

    const circleOne = document.getElementById('circle-one');
    const circleTwo = document.getElementById("circle-two");
    const circleThree =document.getElementById("circle-three");
    const circleFour =document.getElementById("circle-four");

    const smlCircleOne =document.getElementById("small-circle-one");
    const smlCircleTwo = document.getElementById("small-circle-two");
    const smlCircleThree = document.getElementById("small-circle-three");
    const smlCircleFour = document.getElementById("small-circle-four");

        
    const descOne = document.getElementById("circle-one-description");
    const descTwo =document.getElementById("circle-two-description");
    const descThree =document.getElementById("circle-three-description");
    const descFour =document.getElementById("circle-four-description");

    const circles = [circleOne, circleTwo, circleThree, circleFour, smlCircleOne, smlCircleTwo, smlCircleThree, smlCircleFour, descOne, descTwo, descThree, descFour];

    if (!circleOne) return;

    circleOne.addEventListener('mouseenter', function() {
        circleTwo.style.transform = "translate(0px, 25px)";
        circleThree.style.transform = "translate(5px, 25px)";
        circleFour.style.transform = "translate(0px, 0px)";
        smlCircleOne.style.transform = "translate(-35px, 55px)";
        smlCircleTwo.style.transform = "translate(30px, 0px)";
        smlCircleThree.style.transform = "translate(-15px, 55px)";
        smlCircleFour.style.transform = "translate(-43px, -5px)";
        circleOne.style.transform = 'scale(1.5)';
        descOne.style.display = 'block';
        descTwo.style.display = 'none';
        descThree.style.display = 'none';
        descFour.style.display = 'none';
    });

    circleTwo.addEventListener('mouseenter', function() {
      circleOne.style.transform = "translate(40px, 10px)";
      circleThree.style.transform = "translate(10px, 10px)";
      circleFour.style.transform = "translate(25px, 5px)";
      smlCircleOne.style.transform = "translate(32px, 0px)";
      smlCircleTwo.style.transform = "translate(65px, 5px)";
      smlCircleThree.style.transform = "translate(5px, 40px)";
      smlCircleFour.style.transform = "translate(-15px, -20px)";
      circleTwo.style.transform = 'scale(1.5)';
      descOne.style.display = 'none';
      descTwo.style.display = 'block';
      descThree.style.display = 'none';
      descFour.style.display = 'none';
  });

  circleThree.addEventListener('mouseenter', function() {
    circleOne.style.transform = "translate(-12px, -10px)";
    circleTwo.style.transform = "translate(0px, -10px)";
    circleFour.style.transform = "translate(-35px, -4px)";
    smlCircleOne.style.transform = "translate(-10px, 23px)";
    smlCircleTwo.style.transform = "translate(6px, -40px)";
    smlCircleThree.style.transform = "translate(-45px, -10px)";
    smlCircleFour.style.transform = "translate(-75px, 5px)";
    circleThree.style.transform = 'scale(1.5)';
    descOne.style.display = 'none';
    descTwo.style.display = 'none';
    descThree.style.display = 'block';
    descFour.style.display = 'none';
  });

  circleFour.addEventListener('mouseenter', function() {
    circleOne.style.transform = "translate(0px, 0px)";
    circleTwo.style.transform = "translate(-20px, -5px)";
    circleThree.style.transform = "translate(20px, -10px)";
    smlCircleOne.style.transform = "translate(-18px, 15px)";
    smlCircleTwo.style.transform = "translate(9px, -36px)";
    smlCircleThree.style.transform = "translate(-40px, 20px)";
    smlCircleFour.style.transform = "translate(0px, -35px)";
    circleFour.style.transform = 'scale(1.5)';
    descOne.style.display = 'none';
    descTwo.style.display = 'none';
    descThree.style.display = 'none';
    descFour.style.display = 'block';
  });
  }
}  