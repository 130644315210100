import Swiper, {Keyboard, Navigation, Thumbs} from 'swiper';

import 'swiper/css';

export default class GallerySwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.gallery-slides');

        if (!wrapper.length) {
            return;
        }
        
            wrapper.forEach((wrap) => {
                //PREVIEWS
                const galleryThumbs = new Swiper('.swiper-previews', {
                    loop: true,
                    direction: 'vertical',
                    slidesPerView: 3,
                    watchOverflow: true,
                    watchSlidesProgress: true,
                    slideToClickedSlide: true,
                })

                //MAIN
                const galleryMain = new Swiper(wrap as HTMLElement, {
                    loop: true,
                    preventInteractionOnTransition: true, 
                    slideToClickedSlide: true,
                      

                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },

                    thumbs: {
                        swiper: galleryThumbs
                      },

                    modules: [Navigation, Thumbs]
                });

                //MODAL
                const galleryModal = new Swiper('.gallery-modal-swiper', {
                    loop: true,
                    
                    keyboard: {
                        enabled: true,
                        onlyInViewport: true,
                    },

                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },

                      modules: [Navigation, Keyboard],

                })

            galleryMain.on('slideChangeTransitionStart', function() {
                galleryThumbs.slideTo(galleryMain.activeIndex + 1);
            });
                  
        })    
    }
}


