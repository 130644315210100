import '@styles/main.scss';
import 'lity/dist/lity.min.js'; // keep for initialisation
import "lity/dist/lity.min.css";
import GallerySwiper from './components/gallery-swiper';
import ContactForm from './components/contact-form';
import ImageSwiper from './components/image-swiper';
import CookieAccept from './cookie-accept';
import CircleHover from './components/circle-hover-animation';

class App {
    constructor() {
       new GallerySwiper();
    //    new ContactForm();
       new ImageSwiper();
       new CircleHover();

       this.mobileMenu();
       this.searchForm();

       const text = 'This website uses cookies to ensure you get the best experience on our website. By accepting you agree to allow us to use cookies as per our Cookie Policy <a href="https://dev.fambizcommunity.com/wp-content/uploads/2023/01/FamBiznet-COOKIE-POLICY-updated-04042022.pdf" target="_blank">here</a>',
       cookieAccept = new CookieAccept(text);

        if (cookieAccept.shouldShow()) {
            cookieAccept.show();
        }
    }

    mobileMenu() {
        const trigger = document.getElementById('mobile-menu-trigger');
        const overlay = document.querySelector('.menu-overlay');
        const menu = document.querySelector('.max-mega-menu');

        if(menu === null) return;
        if(overlay === null) return;
        if(trigger === null) return;

        const close = () => {
            menu.classList.remove('is-active');
            trigger.classList.remove('is-active');
            document.documentElement.classList.remove('menu-open');
            overlay.classList.remove('is-active');
        }

        trigger.addEventListener('click', () => {
            menu.classList.toggle('is-active');
            trigger.classList.toggle('is-active');
            document.documentElement.classList.toggle('has-menu-open');
            overlay.classList.toggle('is-active');
        });

        overlay.addEventListener('click', () => {
            close();
        });
    }

    searchForm() {
        const trigger = document.querySelector('.fambiz-search-link')
        const overlay = document.getElementById('search-overlay')
        const form = document.querySelector('.search-form');

        if(form === null) return;
        if(overlay === null) return;
        if(trigger === null) return;

        trigger.addEventListener('click', e => {
            e.preventDefault();

            overlay.classList.remove('is-hidden');
        })    

        overlay.addEventListener('click', () => overlay.classList.add('is-hidden'));

        form.addEventListener('click', e => e.stopPropagation());
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
