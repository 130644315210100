import Swiper, {Autoplay, EffectCards} from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-cards';

export default class ImageSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.image-slides');

        if (!wrapper.length) {
            return;
        }
        
            wrapper.forEach((wrap) => {
                const imageSwiper = new Swiper('.image-swiper', {
                    effect: "cards",
                    cardsEffect: {
                        perSlideOffset: 10,
                    },
                    grabCursor: true,
                    loop: true,

                    autoplay: {
                        delay: 5000,
                    },

                    modules: [EffectCards, Autoplay]
                })
                });
    }
}


